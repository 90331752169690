import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';

import './AboutRief.scss';
import { A11y, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types"

const AboutRief = ( {data} ) => {

    const breakpoints: Record<number, SwiperOptions> = {
        0: { slidesPerView: 1, spaceBetween: 8 },
        768: { slidesPerView: 2, spaceBetween: 58 },
        1024: { slidesPerView: 3, spaceBetween: 48 },
        1440: { slidesPerView: 4, spaceBetween: 34 },
    }

    return (
        <Grid fixed={true} className="aboutRief">
            <Row>
                <Col xs={12}>
                    <div className="aboutRief__header" dangerouslySetInnerHTML={{ __html: data.aboutRief.title }} />
                </Col>
                <Col xs={12} className="aboutRief__subtitle_col">
                    <div className="aboutRief__subtitle" dangerouslySetInnerHTML={{ __html: data.aboutRief.subtitle }} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="aboutRief__road_wrapper">
                        {data.aboutRief.items.map( el => (
                            <div className="aboutRief__road">
                                <div className="aboutRief__road_city">{el.city}</div>
                                <div className="aboutRief__road_dot-wrapper">
                                    <div className="aboutRief__road_dot" style={el?.active ? {background: 'black'} : {}}/>
                                </div>
                                <div className="aboutRief__road_date">
                                    <div className="aboutRief__road_date-year">{el.date.year}</div>
                                    <div className="aboutRief__road_date-month">{el.date.month}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="aboutRief__wallpapers">
                        <div className="aboutRief__wallpapers_row">
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-1" />
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-2" />
                        </div>
                        <div className="aboutRief__wallpapers_row-reverse">
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-3" />
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-4" />
                        </div>
                        <div className="aboutRief__wallpapers_row">
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-5" />
                            <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-6" />
                        </div>
                    </div>
                </Col>
            </Row>
            <Swiper
                navigation={true}
                modules={[Pagination, Navigation, A11y]}
                pagination={{ clickable: true }}
                className="aboutRief__slider"
                wrapperClass={"aboutRief__slider_wrapper"}
                breakpoints={breakpoints}
                watchSlidesProgress
            >
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-2" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-3" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-4" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-5" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="aboutRief__wallpapers_image aboutRief__wallpapers_image-6" />
                </SwiperSlide>
            </Swiper>
        </Grid>
    );
};

export default AboutRief;
