import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';

import './Cookies.scss'
import {useCookies} from "react-cookie";

const Cookies = () => {
    const [cookies, setCookie] = useCookies(['cookies-rief'])

    const en = window.location.pathname.includes('/en')

    if (!!cookies['cookies-rief']) {
        return null
    }

    return (
        <Grid fluid className="cookies">
            <Grid className="cookies__banner">
                <Row>
                    <Col xs={12} md={9} xl={10} style={{margin: "auto"}}>
                        {en ?<div className="cookies__banner_text">
                        SberBank processes cookies to personalize services and make the website more convenient to use. You can prohibit the processing of cookies in your browser settings. Please read <a
                            href="https://www.sberbank.ru/privacy/cookies" target={'_blank'} rel="noreferrer">the Cookies Policy</a>. We explain in detail <a
                            href="https://www.sberbank.ru/privacy" target={'_blank'} rel="noreferrer">how SberBank processes and protects your personal data</a>.
                        </div>: <div className="cookies__banner_text">
                            СберБанк обрабатывает cookies с целью персонализации сервисов и чтобы пользоваться веб-сайтом было удобнее. Вы можете запретить обработку сookies в настройках браузера. Пожалуйста, ознакомьтесь <a
                            href="https://www.sberbank.ru/privacy/cookies" target={'_blank'} rel="noreferrer">с политикой использования cookies</a>. Читайте подробнее, <a
                            href="https://www.sberbank.ru/privacy" target={'_blank'} rel="noreferrer">как СберБанк защищает ваши персональные данные</a>.
                        </div>}
                    </Col>
                    <Col xs={12} md={3} xl={2} style={{margin: "auto"}}>
                        <div
                            className="cookies__banner_button"
                            onClick={() => setCookie('cookies-rief', true)}
                        >
                            {en ? 'Accept': 'Принять'}
                        </div>
                    </Col>
                </Row>
            </Grid>
        </Grid>
    );
};

export default Cookies;