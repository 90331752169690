import React, {useEffect, useState} from 'react';

import { Grid, Row, Col } from '../../utils/grid';
import { A11y, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types"

import { getSpeakers } from './api';

import stubImage from '../../assets/stub.png';

import './Speakers.scss';

const Speakers = ( {data} ) => {
    const [dataSpeakers, getDataSpeakers] = useState([])

    useEffect(() => {
        getSpeakers().then(res => getDataSpeakers(res))
    }, [])

    const breakpoints: Record<number, SwiperOptions> = {
        0: { slidesPerView: 1, spaceBetween: 8 },
        768: { slidesPerView: 2, spaceBetween: 58 },
        1024: { slidesPerView: 3, spaceBetween: 48 },
        1440: { slidesPerView: 4, spaceBetween: 34 },
    }

    return (
        <>
            {
                !!dataSpeakers?.length && (
                    <>
                        <Grid className="speakers">
                            <Row>
                                <Col>
                                    <div className="speakers__title">
                                        {data.speakers.title}
                                    </div>
                                </Col>
                            </Row>
                            <Swiper
                                navigation={true}
                                modules={[Pagination, Navigation, A11y]}
                                pagination={{ clickable: true }}
                                className="speakers__slider"
                                wrapperClass={"speakers__slider_wrapper"}
                                breakpoints={breakpoints}
                                watchSlidesProgress
                            >
                                {dataSpeakers.map((slide) => (
                                    <SwiperSlide key={slide.modifiedAt}>
                                        <div className="speakers__card">
                                            <div className="speakers__card_photo">
                                                {slide?.imageUrl && (
                                                    <img src={slide.imageUrl} alt="Спикер" onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = stubImage;
                                                    }} />
                                                )}
                                            </div>
                                            {slide?.fullName && (
                                                <div className="speakers__card_name">{slide.fullName}</div>
                                            )}
                                            {slide?.workPosition && (
                                                <div className="speakers__card_description">{slide.workPosition}</div>
                                            )}
                                            {slide?.workPlace && (
                                                <div className="speakers__card_titul">{slide?.workPlace}</div>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Grid>

                    </>

                )
            }
        </>
    );
};

export default Speakers;