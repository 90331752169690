import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';

import mainLogo from '../../assets/maib.png';
import sberLogo from '../../assets/sber.png';
import mainLogo_en from '../../assets/maib_en.png';
import sberLogo_en from '../../assets/sber_en.png';

import './Footer.scss';

const Footer = ( {data} ) => {
    const en = window.location.pathname.includes('/en')

    return (
        <Grid>
            <Row>
                <Col xs={12}>
                    <div className="footer__separator" />
                </Col>
                <Col xs={12} md={0}>
                    <div className="footer__images">
                        <img src={en ? mainLogo_en : mainLogo} alt="МАИБ"/>
                        <div className="footer__images_separator" />
                        <a href="https://www.sberbank.ru/ru/person">
                            <img src={en ? sberLogo_en : sberLogo} alt="Сбер"/>
                        </a>
                    </div>
                </Col>
            </Row>
            <Row className="footer__legals">
                <Col xs={12} md={6} lg={5} xl={4} order={{md: 1}} className="footer__legals_right">
                    <div className="footer__images_md">
                        <img src={en ? mainLogo_en : mainLogo} alt="МАИБ" style={{height: '42px'}} />
                        <a href="https://www.sberbank.ru/ru/person">
                            <img src={en ? sberLogo_en : sberLogo} alt="Сбер" style={{height: '22px'}} />
                        </a>
                    </div>
                    <div className="footer__legals_item footer__legals_item_md-hidden">{data.footer.generalLicense}</div>
                    <div className="footer__legals_item">{data.footer.registrationNumber}</div>
                </Col>
                <Col xs={12} md={6} lg={7} xl={8} order={{md: 0}}>
                    <div className="footer__legals_item">
                        <a href="https://www.sberbank.ru/privacy/policy#pdn">
                            {data.footer.processingPolicy}
                        </a>
                    </div>
                    <div className="footer__legals_item">
                        <a href={`mailto:${data.footer.email}`} >{data.footer.email}</a>
                    </div>
                    <div className="footer__legals_item">{data.footer.address}</div>
                    <div className="footer__legals_item">© 1997—2024 <a href="https://www.sberbank.ru/ru/person">{data.footer.company}</a></div>
                    <div className="footer__legals_item footer__legals_item_md">{data.footer.generalLicense}</div>
                </Col>
            </Row>
        </Grid>
    );
};

export default Footer;