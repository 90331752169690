import React from 'react';

import "./BottomGradient.scss"

import image from '../../../assets/ariol.webp'
import { useBreakpoint } from "../../../utils/useBreakpoint";

const Gradient = ({top, type}) => {
    const useDevise = useBreakpoint();
    const styles = {
        top: top[useDevise.breakpoint]
    }


    return (
        <img src={image} alt='' className={`bottom-gradient bottom-gradient_${type}`} style={styles} />
    );
};

export default Gradient;
