import {
Container as ReactContainer,
Row as ReactRow,
Col as ReactCol
} from 'react-grid';

const styles = {
    breakpoints: { xs: 0, sm: 375, md: 768, lg: 1024, xl: 1440 },
    containerMaxWidths: { md: 754, lg: 946, xl: 1200 },
    columns: 12,
    gutterWidth: 32
};

export const Grid = props => <ReactContainer {...props} styles={styles} />;
export const Row = props => <ReactRow {...props} styles={styles} />;
export const Col = props => <ReactCol {...props} styles={styles} />
