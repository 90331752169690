
export const getSpeakers = async () => {
    const lang = window.location.pathname.includes('/en') ? 'EN' : 'RU';
    const url = `/proxy/services/dictionary/shared/api/speakers/period/6?language=${lang}&viewStrategy=INCLUSIVE`

    return await fetch(url)
        .then(res => res.json())
        .then(json => json.sort((a, b) => a.priority - b.priority))
        // .then(sort => sort.slice(0, 8))
        .catch(error => {
            console.log(error)
            return []
        })
}