import React from 'react';

// @ts-ignore
import mask_top_xs from '../../../assets/mask_top_xs.png';
import mask_top_md from '../../../assets/mask_top_md.png'
import mask_top_lg from '../../../assets/mask_top_lg.png'

import { useBreakpoint } from "../../../utils/useBreakpoint";

import "./TopGradient.scss"

const TopGradient = ({top, right, bottom, left}) => {
    const useDevise = useBreakpoint();

    const findImage = (() => {
        switch (useDevise.breakpoint) {
            case "md": return mask_top_md
            case "lg": return mask_top_md
            case "xl": return mask_top_lg
            default: return mask_top_xs
        }
    })();

    return (
       <>
           <div className="top-gradient" style={{top, right, bottom, left}} />
           <img src={findImage} alt='' className="top-gradient_img" style={{top, right, bottom, left}} />
       </>
    );
};

export default TopGradient;
