import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';

import { Grid, Row, Col } from '../../utils/grid';

import { getSchedule } from './api';
import { useBreakpoint } from "../../utils/useBreakpoint";

import buttonImage from '../../assets/button_right.svg';
import stubImage from '../../assets/stub.png';

import { ReactComponent as CloseButton} from '../../assets/cross_modal.svg';
import { ReactComponent as MicrophoneImage} from '../../assets/speaker.svg';
import { ReactComponent as ButtonLogo} from '../../assets/download.svg';

import './Schedule.scss';

const lang = window.location.pathname.includes('/en') ? 'EN' : 'RU';

const content = {
    'EN': {
        "tag": 'MODERATOR',
        "button": "More"
    },
    'RU': {
        "tag": "МОДЕРАТОР",
        "button": "Подробнее"
    }
};

const Schedule = ( { data } ) => {
    const useDevise = useBreakpoint();

    const [dataSchedule, getDataSchedule] = useState([])

    useEffect(() => {
        getSchedule().then(res => getDataSchedule(res[0]?.halls[0]))
    }, [])

    const defineNameButton = () => {
        switch (useDevise.breakpoint) {
            case "xs": return data.schedule.button.mobileName
            case "md": return data.schedule.button.mobileName
            default: return data.schedule.button.name
        }
    }

    return (
        <Grid fixed={true} className="schedule">
            <Row>
                <Col xs={12} md={8} lg={8} xl={9}>
                    <div className="schedule__title">
                        {data.schedule.title}
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4} xl={3} className="schedule__button_wrapper">
                    <a className="schedule__button" target={'_blank'} href={data.schedule.button.url}>
                        <ButtonLogo /> {defineNameButton()}
                    </a>
                </Col>
            </Row>
            <Row className="schedule__events">
                {
                    !!dataSchedule?.topics?.length && dataSchedule.topics.map( el => (
                        <Col xs={12}>
                            <div className="schedule__event">
                                <div className="schedule__event_time" style={el.isBreak ? {fontWeight: '400'} : {}}>
                                    {`${el?.startDate?.split('T')[1].slice(0, 5)} - ${el?.endDate.split('T')[1].slice(0, 5)}`}
                                </div>
                                <div className="schedule__event_row">
                                    <div className="schedule__event_header" style={el.isBreak ? {fontWeight: '400'} : {}}>
                                        {el?.title.split('|')[0]}
                                    </div>
                                    {el?.description && (
                                        <>
                                            <div className="schedule__event_description schedule__event_description_hidden" dangerouslySetInnerHTML={{ __html: el.description }} />
                                            <Popup
                                                lockScroll
                                                className="schedule__popup"
                                                modal
                                                nested
                                                trigger={
                                                    <div className="schedule__event_button">
                                                        {content[lang].button} <img src={buttonImage} alt=">"/>
                                                    </div>
                                                }>
                                                {(close) => (
                                                    <div className="schedule__popup_modal">
                                                        <CloseButton onClick={close} className="schedule__popup_modal_close" />
                                                        <div className="schedule__event_time">
                                                            {`${el?.startDate?.split('T')[1].slice(0, 5)} - ${el?.endDate.split('T')[1].slice(0, 5)}`}
                                                        </div>
                                                        <div className="schedule__event_header">
                                                            {el?.title.split('|')[0]}
                                                        </div>
                                                        <div className="schedule__event_description" dangerouslySetInnerHTML={{ __html: el.description }} />
                                                        {el?.leadSpeakers?.map( (lead) => (
                                                                <div className="schedule__event_speaker">
                                                                    <img src={lead.imageUrl} alt="Спикер" onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = stubImage;
                                                                    }} />
                                                                    <div>
                                                                        <div className="schedule__event_name">{lead.fullName}</div>
                                                                        <div className="schedule__event_place">
                                                                            <div className="schedule__event_place_sep"/>
                                                                            {lead.workPlace}
                                                                        </div>
                                                                        <div className="schedule__event_tag">
                                                                            <MicrophoneImage />
                                                                            {content[lang].tag}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </Popup>
                                        </>
                                    )}
                                    <div className="schedule__event_row_speakers">
                                        {el?.leadSpeakers?.map( (lead) => (
                                                <div className="schedule__event_speaker">
                                                    <img src={lead.imageUrl} alt="Спикер" onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = stubImage;
                                                    }} />
                                                    <div>
                                                        <div className="schedule__event_name">{lead.fullName}</div>
                                                        <div className="schedule__event_place">
                                                            <div className="schedule__event_place_sep"/>
                                                            {lead.workPlace}
                                                        </div>
                                                        <div className="schedule__event_tag">
                                                            <MicrophoneImage />
                                                            {content[lang].tag}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="schedule__event_row_speakers">
                                        {el?.speakers?.map( (speaker) => (
                                            <div className="schedule__event_speaker">
                                                <img src={speaker.imageUrl} alt="Спикер" onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = stubImage;
                                                }} />
                                                <div>
                                                    <div className="schedule__event_name">{speaker.fullName}</div>
                                                    <div className="schedule__event_place">
                                                        <div className="schedule__event_place_sep"/>
                                                        {speaker.workPlace}
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="schedule__event_separator" />
                        </Col>
                    ))
                }
            </Row>
        </Grid>
    );
};

export default Schedule;