import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';



import './AboutForum.scss'

const AboutForum = ( {data} ) => {
    return (
        <Grid className="aboutForum">
            <Row className="aboutForum__top">
                <Col xs={12} lg={8} xl={8} order={{xs: 1, lg: 0}}>
                    <div
                        className="aboutForum__title"
                        dangerouslySetInnerHTML={{ __html: data.aboutForum.title }}
                    />
                    <div
                        className="aboutForum__subtitle"
                        dangerouslySetInnerHTML={{ __html: data.aboutForum.subtitle }}
                    />
                </Col>
                <Col xs={12} lg={4} xl={4} order={{xs: 0, lg: 1}}>
                    <div className="aboutForum__image aboutForum__image_top" />
                </Col>
            </Row>
            <Row className="aboutForum__bottom">
                <Col xs={12} lg={8} xl={8}>
                    <div className="aboutForum__image aboutForum__image_bottom" />
                </Col>
                <Col xs={12} lg={4} xl={4}>
                    <div className="aboutForum__targets">
                        <div
                            className="aboutForum__targets_title"
                            dangerouslySetInnerHTML={{ __html: data.aboutForum.targets.title }}
                        />
                        {
                            data.aboutForum.targets.items.map((item, index, arr) => (
                                <>
                                    <div className="aboutForum__targets_item">
                                        <div className="aboutForum__targets_item_number">
                                            {`0${index + 1}`}
                                        </div>
                                        <div
                                            className="aboutForum__targets_item_subtitle"
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    </div>
                                    { index < arr.length - 1 && <div className="aboutForum__targets_separator" /> }
                                </>
                            ))
                        }
                    </div>
                </Col>
            </Row>
        </Grid>
    );
};

export default AboutForum;