
const getData = async () => {
    // const url = window.location.pathname === '/en' ? '/common/assets/rief/data_en.json' : '/common/assets/rief/data.json'
    const url = window.location.pathname.includes('/en') ? '/common/assets/rief/data_en.json' : '/common/assets/rief/data.json'

    return await fetch(url)
        .then(res => res.json())
        .catch(error => {
            console.log(error)
        })
}

export default getData;
