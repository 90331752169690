import React from 'react';
import { Grid, Row, Col } from '../../utils/grid';

import data from '../../data.json'

import './Banner.scss'
import { useBreakpoint } from "../../utils/useBreakpoint";

const Banner = ( {data} ) => {

    const useDevise = useBreakpoint();
    const findDate = (() => {
        switch (useDevise.breakpoint) {
            case "lg": return data.header.eventDate.date.desktop
            case "xl": return data.header.eventDate.date.desktop
            default: return data.header.eventDate.date.mobile
        }
    })();

    const findtitle = (block) => {
        switch (useDevise.breakpoint) {
            case "xs": return data.header[block].titleMobile
            case "md": return data.header[block].titleMobile
            default: return data.header[block].title
        }
    };
    return (
        <Grid>
            <Row>
                <Col xs={12} lg={8} xl={8} order={{lg: 0}}>
                    <h1 className="banner__title" dangerouslySetInnerHTML={{ __html: data.header.title}} />
                    <div className="banner__subtitle">
                        {data.header.subtitle}
                    </div>
                </Col>
                <Col xs={12} md={12} lg={4} xl={4} order={{lg: 2}}>
                    <div className="banner__event">
                        <div className="banner__event_row">
                            <div className="banner__event_title">
                                {findtitle('eventDate')}
                            </div>
                            <div className="banner__event_date">
                                {findDate}
                            </div>
                        </div>
                        <div className="banner__event_row-separator" />
                        <div className="banner__event_row">
                            <div className="banner__event_title">
                                {findtitle('where')}
                            </div>
                            <a href={data.header.where.link} target={'_blank'} className="banner__event_date" rel="noreferrer">
                                {data.header.where.city}
                            </a>
                        </div>
                        {/*<div className="banner__event_button">*/}
                        {/*    <a className="banner__button" href={data.header.button.url}>*/}
                        {/*        {data.header.button.name}*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </Col>
                {/*<Col xs={12} order={{lg: 3}} className="banner__button_col">*/}
                {/*    <a className="banner__button" href={data.header.button.url}>*/}
                {/*        {data.header.button.name}*/}
                {/*    </a>*/}
                {/*</Col>*/}
            </Row>
        </Grid>
    );
};

export default Banner;