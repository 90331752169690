import jsonMock from '../../data.json'

export const getSchedule = async () => {
    // const url = window.location.pathname === '/en' ? '/common/assets/rief/schedule_mock_en.json' : '/common/assets/rief/schedule_mock.json'
    const lang = window.location.pathname.includes('/en') ? 'EN' : 'RU';
    const url = `/proxy/services/dictionary/shared/api/v2/schedule/period/6?language=${lang}&viewStrategy=INCLUSIVE`

    return await fetch(url)
        .then(res => res.json())
        // .then(() => jsonMock)
        .catch(error => {
            console.log(error)
            return []
        })
}