import React from 'react';
import { Grid, Row, Col } from '../../utils/grid';
// @ts-ignore
import { ReactComponent as Logo } from '../../assets/logo.svg';
import maibLogo from '../../assets/maib.png';
import sberLogo from '../../assets/sber.png';


// @ts-ignore
import { ReactComponent as Logo_en } from '../../assets/logo_en.svg';
import maibLogo_en from '../../assets/maib_en.png';
import sberLogo_en from '../../assets/sber_en.png';

import './Header.scss';
import TopGradient from "../Gradients/TopGradient/TopGradient";

const isDev = process.env.NODE_ENV === "development"
const prefix = isDev ? "/common/pages/rief" : '' 

const Header = () => {
    const en = window.location.pathname.includes('/en')
    const url = en ? `${prefix}/` : `${prefix}/en`
    
    return (
        <Grid>
            <TopGradient/>
            <Row>
                <Col xs={12} className="header">
                    <div className="header__logosWrapper">
                        <a href="/" className="header__logo_link">
                            {
                                en ? <Logo_en className="header__logo_main"/> : <Logo className="header__logo_main"/>
                            }
                        </a>
                        <div className="header__logosWrapper_desktop">
                            <div className="header__logo_separator"/>
                            <img src={en ? maibLogo_en : maibLogo} alt="МАИБ" className="header__logo_maib"/>
                            <div className="header__logo_separator"/>
                            <a href="https://www.sberbank.ru/ru/person">
                                <img src={en ? sberLogo_en : sberLogo} alt="Сбер" className="header__logo_sber"/>
                            </a>
                        </div>
                    </div>
                    <a href={url} className="header__langSwitch">{en ? "РУС" : 'ENG'}</a>
                </Col>
            </Row>
        </Grid>
    );
}

export default Header;
