import React, {useEffect, useState} from 'react';

import Header from './Sections/header/Header';
import Banner from "./Sections/banner/Banner";
import Footer from "./Sections/footer/Footer";
import Gradient from "./Sections/Gradients/BottomGradient/BottomGradient";
import Cookies from "./components/Cookies/Cookies";
import AboutForum from "./Sections/AboutForum/AboutForum";
import BusinessFacts from "./Sections/BusinessFacts/BusinessFacts";
import Speakers from "./Sections/Speakers/Speakers";
import Schedule from "./Sections/Schedule/Schedule";
import HowOrder from "./Sections/HowOrder/HowOrder";
import AboutRief from "./Sections/AboutRief/AboutRief";

// @ts-ignore
import getData from './getData';

import './styles/fonts.scss'
import './App.css';

function App() {
    const [data, setData] = useState(false)

    useEffect(() => {
        getData().then( (res: any) => setData(res))
    }, [])

    if (data) {
        return (
            <div className="rief">
                <Gradient type="left" top={{xs: '958px', md: '1249px', lg: '570px', xl: '570px'}} />
                <Gradient type="right" top={{xs: '2490px', md: '2740px', lg: '2100px', xl: '2100px'}} />
                <Gradient type="left" top={{xs: '4550px', md: '5390px', lg: '4170px', xl: '4360px'}} />
                <Gradient type="right" top={{xs: '5250px', md: '6190px', lg: '5194px', xl: '5534px'}} />
                <Gradient type="left" top={{xs: '52350px', md: '6950px', lg: '5994px', xl: '6484px'}} />
                <Header />
                <Banner data={data} />
                <AboutForum data={data} />
                <BusinessFacts data={data} />
                <Speakers data={data} />
                <Schedule data={data} />
                {/*<HowOrder data={data} />*/}
                <AboutRief data={data} />
                <Footer data={data} />
                <Cookies />
            </div>
        );
    }
    return <div className="rief" />
}

export default App;
