import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';

import './BusinessFacts.scss';

const BusinessFacts = ( {data} ) => {
    return (
        <Grid fixed={true} className="businessFacts">
            <Row>
                <Col xs={12} md={9} lg={7} xl={7}>
                    <div className="businessFacts__header">
                        {data.businessFacts.title}
                    </div>
                </Col>
            </Row>
            <Row>
                {data.businessFacts.items.map( item => (
                    <Col xs={6} lg={4} className="businessFacts__card">
                        <div className="businessFacts__card_fact">
                            {item.fact}
                        </div>
                        <div className="businessFacts__card_separator" />
                        <div className="businessFacts__card_subfuct">
                            {item.subfact}
                        </div>
                    </Col>
                ))}
            </Row>
        </Grid>
    );
};

export default BusinessFacts;
